import { Component, OnInit } from '@angular/core';
import { speakers, Speaker } from "../speaker.model";
import { Observable, of } from 'rxjs';
import { participantsList } from "../participant.model";
import { AuthService } from 'src/app/auth/auth.service';

declare const Calendly;

@Component({
  selector: 'app-speakers-list',
  templateUrl: './speakers-list.component.html',
  styleUrls: ['./speakers-list.component.scss']
})
export class SpeakersListComponent implements OnInit {

  speakers: Speaker[];

  allParticipants: Array<any>;
  investors: Array<any>;
  corporates: Array<any>;
  startups: Array<any>;
  universities: Array<any>;
  students: Array<any>;

  constructor(
    private auth: AuthService
  ) {
    of(speakers).subscribe((data) => {
      this.speakers = data;
    })
  }

  ngOnInit(): void {
    of(participantsList).subscribe((data) => {
      this.allParticipants = data;
      this.divideParticipants(this.allParticipants);
    })
  }

  scheduleMeeting(speakerId) {
    const name = this.auth.user.displayName;
    const email = this.auth.user.email;
    const link = this.speakers.find((s: Speaker) => {
      return speakerId == s.id;
    }).calendlyLink;
    // Calendly.initBadgeWidget({ url: 'https://calendly.com/dawid-armatys/spotkanie-z-prelegentem?hide_event_type_details=1', text: 'Schedule time with me', color: '#00a2ff', textColor: '#ffffff', branding: true });
    Calendly.showPopupWidget(`${link}?hide_event_type_details=1`, 'PopupText', {
      prefill: {
        name: name,
        email: email
      }
    })
  }

  divideParticipants = (participants: Array<any>) => {
    this.investors = participants.filter((p) => {
      return p.category == 'Investor';
    })
    this.corporates = participants.filter((p) => {
      return p.category == 'Corporate';
    })
    this.startups = participants.filter((p) => {
      return p.category == 'Start-up or scale-up';
    })
    this.universities = participants.filter((p) => {
      return p.category == 'University';
    })
    this.students = participants.filter((p) => {
      return p.category == 'Students and learners';
    })
  }

}
