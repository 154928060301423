<div class="container">
    <div class="row">
        <div class="col-12">
            <ul class="list-group mt-4">
                <li
                    *ngFor="let quiz of quizes | async"
                    class="list-group-item d-flex justify-content-between align-items-center">
                    <a [routerLink]="[ '/u', 'quiz', quiz.id ]">{{quiz.title}}</a>
                </li>
            </ul>
        </div>
    </div>
</div>
