import { Component, OnInit, Input } from '@angular/core';
import { QuizService } from '../quiz.service';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { Quiz, QuizQuestion } from '../quiz.model';
import { StreamService } from 'src/app/client/stream/stream.service';
import { SideQuizService } from 'src/app/client/side-quiz/side-quiz.service';

@Component({
  selector: 'app-quiz',
  templateUrl: './quiz.component.html',
  styleUrls: ['./quiz.component.scss']
})
export class QuizComponent implements OnInit {

  quiz: Quiz;
  currentQuestion: QuizQuestion = {
    a: '',
    b: '',
    c: '',
    d: '',
    content: '',
    correct: 'a'
  }
  answersMarked = {
    a: false,
    b: false,
    c: false,
    d: false,
  };

  markedAnswer: any = {};
  currentQuestionId = 0;
  totalQuestions;

  correctAnswers = 0;

  inactiveState = false;

  quizFinished = false;

  startTime = 0;
  endTime = 0;
  timeDiff = 0;

  type: string;

  userAnswers = [];
  constructor(
    private qs: QuizService,
    private activatedRoute: ActivatedRoute,
    private streamSrv: StreamService,
    private sqSrv: SideQuizService
  ) { }

  @Input('dynamic') dynamicQuiz: boolean;

  ngOnInit(): void {
    if (this.dynamicQuiz) {
      const streamId = this.streamSrv.currentStreamId$.getValue()
      this.qs.getQuizByStreamId(streamId).subscribe((quizes: Quiz[]) => {
        const visibleQuiz = quizes.find((q: Quiz) => {
          return q.visible;
        })
        if (visibleQuiz) {
          console.log('VISIBLE QUIZ');
          this.type = visibleQuiz.type;
          this.initQuiz(visibleQuiz);
          this.sqSrv.quizOpened.next(true);
        } else {
          this.sqSrv.quizOpened.next(false);
          return;
        }
      })
    } else {
      this.activatedRoute.params.subscribe(params => {
        if (params.quizId) {
          this.qs.getQuiz(params.quizId).then((quiz: Quiz) => {
            this.type = quiz.type;
            this.initQuiz(quiz);
          })
        }
      })
    }
  }

  initQuiz = (quiz: Quiz) => {
    this.quiz = quiz;
    console.log(quiz);
    this.totalQuestions = quiz.questions.length;
    this.setQuestion(0);
    for (const i in this.totalQuestions) {
      this.userAnswers.push(false);
    }
    this.startTime = Date.now();
  }

  setQuestion = (id) => {
    this.currentQuestion = this.quiz.questions[id];
  }

  markAnswer(e, answer) {
    if (this.inactiveState) return;
    this.resetAnswers()
    this.answersMarked[answer] = !this.answersMarked[answer];
    this.markedAnswer = answer;
    // const isCorrect = this.checkAnswer(answer);
  }

  checkAnswer() {
    if (this.inactiveState) return;
    this.inactiveState = true;
    if (this.type == 'quiz') {
      this.userAnswers[this.currentQuestionId] = this.markedAnswer;
      if (this.markedAnswer == this.currentQuestion.correct) {
        this.correctAnswers++;
      }
      setTimeout(() => {
        this.nextQuestion()
      }, 2000);
    } else {
      this.finishAnkieta()
    }
  }

  nextQuestion = () => {
    this.resetAnswers();
    this.inactiveState = false;
    let nextQ = this.quiz.questions[this.currentQuestionId + 1];
    if (nextQ) {
      this.currentQuestion = nextQ;
      this.currentQuestionId = this.currentQuestionId + 1;
    } else {
      this.finishQuiz()
    }
  }

  finishQuiz() {
    this.endTime = Date.now();
    this.timeDiff = this.endTime - this.startTime;
    this.timeDiff /= 1000;
    this.timeDiff = Math.round((this.timeDiff + Number.EPSILON) * 100) / 100;
    console.log('Koniec quizu, liczba poprawnych odpowiedzi to: ', this.correctAnswers)
    this.quizFinished = true;
    this.saveQuiz();
    if (this.dynamicQuiz) {
      setTimeout(() => {
        this.sqSrv.quizOpened.next(false);
      }, 3000);
    }
  }

  finishAnkieta() {
    this.quizFinished = true;
    console.log(this.userAnswers, this.correctAnswers, this.markedAnswer);
    this.saveAnkieta();
    this.qs.getAnkietaResults(this.quiz.id).subscribe((data: any) => {
      this.qs.currentQuizData.next(data[this.quiz.id])
    })
  }

  saveQuiz() {
    this.qs.saveQuizResult(this.userAnswers, this.correctAnswers, this.quiz.id, this.timeDiff)
  }

  saveAnkieta() {
    this.qs.saveAnkietaResults(this.quiz.id, this.markedAnswer);
  }

  resetAnswers() {
    Object.keys(this.answersMarked).forEach(key => {
      this.answersMarked[key] = false;
    })
  }


}
