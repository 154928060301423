<div class="container">
    <ng-container *ngIf="speaker$ | async as speaker">
        <div class="row">
            <div class="col-12 speaker-info">
                <img [src]="speaker.image" alt="">
                <div class="speaker-details">
                    <h3>{{speaker.name}}</h3>
                    <h4>{{speaker.company}}</h4>
                    <h4>{{speaker.position}}</h4>
                </div>
                <button class="bl-btn bl-btn-primary" (click)="meetSpeaker()">Umów się z prelegentem</button>
            </div>
        </div>
        <div class="row">
            <div class="col-12 col-lg-6 d-flex align-items-stretch p-0">
                <div class="speaker-description">
                    <h3>O mnie</h3>
                    <p>{{speaker.description}}</p>
                </div>
            </div>
            <div class="col-12 col-lg-6 d-flex align-items-stretch p-0">
                <div class="speaker-contact">
                    <h3>Kontakt</h3>
                    <p><strong>Email: </strong>{{speaker.email}}</p>
                </div>
            </div>
        </div>
    </ng-container>
</div>