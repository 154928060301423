import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { QuizListComponent } from './quiz-list/quiz-list.component';
import { SharedModule } from '../shared/shared.module';
import { ClientRoutingModule } from '../client/client-routing.module';
import { QuizComponent } from './quiz/quiz.component';
import { ChartComponent } from '../client/quiz/chart/chart.component';



@NgModule({
  declarations: [
    QuizListComponent,
    QuizComponent,
    ChartComponent
  ],
  imports: [
    CommonModule,
    ClientRoutingModule
  ],
  exports: [
    QuizComponent,
    ChartComponent
  ]
})
export class QuizModule { }
