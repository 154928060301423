import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(
    private afs: AngularFirestore
  ) { }

  getUserData(uid) {
    return this.afs.doc(`users/${uid}`).valueChanges();
  }

  updateUserData(user) {
    return this.afs.doc(`users/${user.uid}`).update(user)
      .catch(err => {
        throw new Error(err.message);
      });
  }
}
