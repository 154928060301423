<div class="wrapper-100 bl-chats-list">
    <div class="chat-list-item"
        *ngFor="let chat of chats$ | async | async"
        [ngClass]="{'active': activeChatId == chat.id}"
        [routerLink]="[{id: chat.id}]"
        >
        <h3>{{chat.title}}</h3>
        <p>{{ chat.messages[chat.messages.length - 1].content | slice:0:30 }}</p>
    </div>
</div>
<!-- (click)="changeChat($event, chat.id)" -->