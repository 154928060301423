export const participantsList = [
    {
        displayName: "FirstName LastName",
        email: "mail@example.com",
        uid: "32QxIhzq9dckdExJ1p6P3fn3ZHS2",
        company: "Company",
        category: 'Investor'
    },
    {
        displayName: "FirstName LastName",
        email: "mail@example.com",
        uid: "32QxIhzq9dckdExJ1p6P3fn3ZHS2",
        company: "Company",
        category: 'Investor'
    },
    {
        displayName: "FirstName LastName",
        email: "mail@example.com",
        uid: "32QxIhzq9dckdExJ1p6P3fn3ZHS2",
        company: "Company",
        category: 'University'
    },
    {
        displayName: "FirstName LastName",
        email: "mail@example.com",
        uid: "32QxIhzq9dckdExJ1p6P3fn3ZHS2",
        company: "Company",
        category: 'Investor'
    },
    {
        displayName: "FirstName LastName",
        email: "mail@example.com",
        uid: "32QxIhzq9dckdExJ1p6P3fn3ZHS2",
        company: "Company",
        category: 'Investor'
    },
    {
        displayName: "FirstName LastName",
        email: "mail@example.com",
        uid: "32QxIhzq9dckdExJ1p6P3fn3ZHS2",
        company: "Company",
        category: 'Investor'
    },
    {
        displayName: "FirstName LastName",
        email: "mail@example.com",
        uid: "32QxIhzq9dckdExJ1p6P3fn3ZHS2",
        company: "Company",
        category: 'University'
    },
    {
        displayName: "FirstName LastName",
        email: "mail@example.com",
        uid: "32QxIhzq9dckdExJ1p6P3fn3ZHS2",
        company: "Company",
        category: 'Corporate'
    },
    {
        displayName: "FirstName LastName",
        email: "mail@example.com",
        uid: "32QxIhzq9dckdExJ1p6P3fn3ZHS2",
        company: "Company",
        category: 'Corporate'
    },
    {
        displayName: "FirstName LastName",
        email: "mail@example.com",
        uid: "32QxIhzq9dckdExJ1p6P3fn3ZHS2",
        company: "Company",
        category: 'Corporate'
    },
    {
        displayName: "FirstName LastName",
        email: "mail@example.com",
        uid: "32QxIhzq9dckdExJ1p6P3fn3ZHS2",
        company: "Company",
        category: 'Corporate'
    },
    {
        displayName: "FirstName LastName",
        email: "mail@example.com",
        uid: "32QxIhzq9dckdExJ1p6P3fn3ZHS2",
        company: "Company",
        category: 'University'
    },
    {
        displayName: "FirstName LastName",
        email: "mail@example.com",
        uid: "32QxIhzq9dckdExJ1p6P3fn3ZHS2",
        company: "Company",
        category: 'Start-up or scale-up'
    },
    {
        displayName: "FirstName LastName",
        email: "mail@example.com",
        uid: "32QxIhzq9dckdExJ1p6P3fn3ZHS2",
        company: "Company",
        category: 'Start-up or scale-up'
    },
    {
        displayName: "FirstName LastName",
        email: "mail@example.com",
        uid: "32QxIhzq9dckdExJ1p6P3fn3ZHS2",
        company: "Company",
        category: 'University'
    },
    {
        displayName: "FirstName LastName",
        email: "mail@example.com",
        uid: "32QxIhzq9dckdExJ1p6P3fn3ZHS2",
        company: "Company",
        category: 'University'
    },
    {
        displayName: "FirstName LastName",
        email: "mail@example.com",
        uid: "32QxIhzq9dckdExJ1p6P3fn3ZHS2",
        company: "Company",
        category: 'Start-up or scale-up'
    },
    {
        displayName: "FirstName LastName",
        email: "mail@example.com",
        uid: "32QxIhzq9dckdExJ1p6P3fn3ZHS2",
        company: "Company",
        category: 'Start-up or scale-up'
    },
    {
        displayName: "FirstName LastName",
        email: "mail@example.com",
        uid: "32QxIhzq9dckdExJ1p6P3fn3ZHS2",
        company: "Company",
        category: 'Students and learners'
    },
    {
        displayName: "FirstName LastName",
        email: "mail@example.com",
        uid: "32QxIhzq9dckdExJ1p6P3fn3ZHS2",
        company: "Company",
        category: 'Students and learners'
    },
    {
        displayName: "FirstName LastName",
        email: "mail@example.com",
        uid: "32QxIhzq9dckdExJ1p6P3fn3ZHS2",
        company: "Company",
        category: 'Students and learners'
    },
    {
        displayName: "FirstName LastName",
        email: "mail@example.com",
        uid: "32QxIhzq9dckdExJ1p6P3fn3ZHS2",
        company: "Company",
        category: 'Students and learners'
    },
    {
        displayName: "FirstName LastName",
        email: "mail@example.com",
        uid: "32QxIhzq9dckdExJ1p6P3fn3ZHS2",
        company: "Company",
        category: 'University'
    },
    {
        displayName: "FirstName LastName",
        email: "mail@example.com",
        uid: "32QxIhzq9dckdExJ1p6P3fn3ZHS2",
        company: "Company",
        category: 'Students and learners'
    },
]