import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { speakers, Speaker } from "./speaker.model";
import { Observable, of } from 'rxjs';
import { AuthService } from 'src/app/auth/auth.service';

declare const Calendly;

@Component({
  selector: 'app-speaker-info',
  templateUrl: './speaker-info.component.html',
  styleUrls: ['./speaker-info.component.scss']
})
export class SpeakerInfoComponent implements OnInit {

  speaker$: Observable<Speaker>;

  constructor(
    private activatedRoute: ActivatedRoute,
    private auth: AuthService
  ) { 
  }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(params => {
      let speaker = speakers.find(s => {
        return s.id == params.speakerId
      });
      this.speaker$ = of(speaker);
    })
    
    // Calendly.initBadgeWidget({ url: 'https://calendly.com/dawid-armatys/spotkanie-z-prelegentem?hide_event_type_details=1', text: 'Schedule time with me', color: '#00a2ff', textColor: '#ffffff', branding: false });
  }

  meetSpeaker() {
    const name = this.auth.user.displayName;
    const email = this.auth.user.email;
    // Calendly.initBadgeWidget({ url: 'https://calendly.com/dawid-armatys/spotkanie-z-prelegentem?hide_event_type_details=1', text: 'Schedule time with me', color: '#00a2ff', textColor: '#ffffff', branding: true });
    Calendly.showPopupWidget('https://calendly.com/dawid-armatys/spotkanie-z-prelegentem?hide_event_type_details=1', 'PopupText', {
     prefill: {
       name: name,
       email: email
     }
    })
  }

}
