// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebaseConfig : {
    apiKey: "AIzaSyALjYsOfizzNzOfniyyYsikfys-e649CC4",
    authDomain: "conference-system-b3ae1.firebaseapp.com",
    databaseURL: "https://conference-system-b3ae1.firebaseio.com",
    projectId: "conference-system-b3ae1",
    storageBucket: "conference-system-b3ae1.appspot.com",
    messagingSenderId: "74203417760",
    appId: "1:74203417760:web:5fec79f4b69a278811cdc5"
  },
  firebaseFunction: 'http://localhost:5000/conference-system-b3ae1/us-central1/api/'
}

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
