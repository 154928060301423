import { Component, OnInit } from '@angular/core';
import { ChatService } from '../chat.service';
import { AuthService } from 'src/app/auth/auth.service';
import { Observable, from } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-chats-list',
  templateUrl: './chats-list.component.html',
  styleUrls: ['./chats-list.component.scss']
})
export class ChatsListComponent implements OnInit {

  chats$
  activeChatId

  constructor(
    private cs: ChatService,
    private auth: AuthService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.chats$ = this.cs.getChatsList()
    this.activatedRoute.params.subscribe((params) => {
      if (!params.id) {
        this.activeChatId = 'global';
        this.changeChat(null, this.activeChatId);
      }
      this.activeChatId = params.id;
    })
  }

  changeChat(e, chatId) {
    // let elem: HTMLElement = e.target;
    // document.querySelectorAll('.chat-list-item').forEach((el) => {
    //   el.classList.remove('active');
    // })
    // elem.classList.add('active');
    this.router.navigate(['u', 'chats', {id: chatId}]);
  }

}
