import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/auth/auth.service';
import { UserService } from './user.service';
import { switchMap, flatMap, take } from 'rxjs/operators';
import { of } from 'rxjs';
import { User } from '../../auth/user.model';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss']
})
export class UserComponent implements OnInit {

  userUpdated = false;

  user: User = {
    email: '',
    uid: '',
    roles: {
      client: true
    }
  };
  userData;
  constructor(
    public auth: AuthService,
    private userSrv: UserService
  ) {
    
    this.auth.user$.pipe(
      take(1),
      flatMap((authUser) => {
        if(authUser) {
          console.log(authUser);
          return this.userSrv.getUserData(authUser.uid)
        } else {
          return of(null);
        }
      })
    ).subscribe((res: any) => {
      this.user = res;
    })
  }

  ngOnInit(): void {
  }

  updateUserData = () => {
    this.user.displayName = `${this.user.name} ${this.user.lastname}`;
    this.userSrv.updateUserData(this.user).then((res) => {
      console.log(this.user);
      this.userUpdated = true;
    })
  }

}
