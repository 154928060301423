<section class="wrapper-100">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <h2 class="text-left">ROOMS</h2>
            </div>
        </div>
    </div>
    <div class="wave">
        <img src="../../../assets/images/wave1.svg" alt="">
    </div>
    <div class="container">
        <div class="row">
            <div class="col-12 col-md-4 d-flex justify-content-center">
                <div class="room-wrapper">
                    <h3>Startups</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                    <button class="bl-btn bl-btn-outline">Join</button>
                </div>
            </div>
            <div class="col-12 col-md-4 d-flex justify-content-center">
                <div class="room-wrapper">
                    <h3>Investors</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                    <button class="bl-btn bl-btn-outline">Join</button>
                </div>
            </div>
            <div class="col-12 col-md-4 d-flex justify-content-center">
                <div class="room-wrapper">
                    <h3>Organizers</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                    <button class="bl-btn bl-btn-outline">Join</button>
                </div>
            </div>
        </div>
    </div>
    <div class="wave bottom-wave">
        <img src="../../../assets/images/wave2.svg" alt="">
        <!-- <svg xmlns="http://www.w3.org/2000/svg" width="1278.982" height="89.252" viewBox="0 0 1278.982 89.252"><path d="M.856,1486.43c85.865-1.982,182.122,2.084,288.7,15.347,537.905,66.877,613.911,66.572,703.04,66.623,33.906.05,102.585,1.423,190.382-8.335,30.242-3.354,62.75-8.029,96.856-14.533v-57.069c-134.426,37.4-338.4,68.6-612.446,41.264-462.9-46.193-585-51.224-666.536-50.411Z" transform="translate(-0.856 -1479.24)" fill="#fdcc38"/></svg> -->
    </div>
</section>
<!-- <div class="container-fluid wrapper-100">
    <div class="row wrapper-100">
                
    </div>
</div> -->
