<div class="container">
    <div class="row">
        <div class="col-12">
            <ul class="list-group mt-4">
                <li
                    *ngFor="let stream of streams | async"
                    class="list-group-item d-flex justify-content-between align-items-center">
                    <a [routerLink]="[ '/u', 'stream', stream.id ]">{{stream.name}}</a>
                    <span class="short-description">{{stream.shortDescription}}</span>
                <span *ngIf="stream.active" class="badge badge-danger badge-pill">Aktywny</span>
                </li>
            </ul>
        </div>
    </div>
</div>
