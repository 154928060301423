interface ErrorsMessages {
  [index: string]: string
}

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ErrorsTranslateService {

  errors: ErrorsMessages = {
    'email-already-exists': "Istnieje już konto z powiązanym adresem email",
    'invalid-email': "Podany adres email jest nieprawidłowy",
    'invalid-password': "Podane hasło jest nieprawidłowe",
    'user-not-found': "Podany użytkownik nie istnieje",
    'wrong-password': "Podane adres email lub hasło jest nieprawidłowe"
  }

  constructor() { }

  public getAuthErrorMsg(errCode) {
    const code = errCode.split('/')[1]
    if (this.errors[code]) {
      return this.errors[code];
    } else {
      return 'Wystąpił błąd, spróbuj ponownie'
    }
  }
}
