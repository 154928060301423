import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-chats',
  templateUrl: './chats.component.html',
  styleUrls: ['./chats.component.scss']
})
export class ChatsComponent implements OnInit {

  constructor(private activatedRoute: ActivatedRoute,
    private router: Router) {
      // this.activatedRoute.params.subscribe((params) => {
      //   if (!params.id) {
      //     this.router.navigate(['/', 'client', 'chats', 'global'])
      //   }
      // })
    }

  ngOnInit(): void {
  }

}
