<div class="client-wrapper">
    <section class="top-bar">
      <div class="container">
        <div class="row page-heading login-heading">
            <div class="logo logo-header logo-header__login">
                <img src="../../../assets/images/logo-header.svg" alt="Logo Berrylife">
            </div>
            <div class="login-heading__info">
                <span>Platforma konferencyjna online</span>
            </div>
        </div>
      </div>
    </section>
    <section class="bl-main-wrapper">
      <div class="container">
        <div class="row login-wrapper wrapper-100">
            <div class="col-12 col-md-8 offset-md-2 col-lg-4 offset-lg-4">
                <div class="form-wrapper">
                    <h2>Zarejsetruj się</h2>
                    <form #loginForm="ngForm" (ngSubmit)="register()">
                        <div class="bl-form-field">
                            <label for="email" class="input-label">Adres e-mail</label>
                            <input type="email" class="bl-form-control" id="email" name="email" [(ngModel)]="userForm.email" autofocus required>
                        </div>
                        <div class="bl-form-field">
                            <label for="password" class="input-label">Hasło</label>
                            <input type="password" class="bl-form-control" id="password" name="password" [(ngModel)]="userForm.password" autofocus required>
                        </div>
                        <div class="bl-form-field">
                            <label for="name" class="input-label">Imię</label>
                            <input type="name" class="bl-form-control" id="name" name="name" [(ngModel)]="userForm.name" autofocus required>
                        </div>
                        <div class="bl-form-field">
                            <label for="lastname" class="input-label">Nazwisko</label>
                            <input type="lastname" class="bl-form-control" id="lastname" name="lastname" [(ngModel)]="userForm.lastname" autofocus required>
                        </div>
                        <!-- <div class="bl-form-field">
                            <label for="phone" class="input-label">Telefon</label>
                            <input type="phone" class="bl-form-control" id="phone" name="phone" [(ngModel)]="userForm.phone" autofocus required>
                        </div>
                        <div class="bl-form-field">
                            <label for="address" class="input-label">Adres</label>
                            <input type="address" class="bl-form-control" id="address" name="address" [(ngModel)]="userForm.address" autofocus required>
                        </div>
                        <div class="bl-form-field">
                            <label for="postalCode" class="input-label">Kod pocztowy</label>
                            <input type="postalCode" class="bl-form-control" id="postalCode" name="postalCode" [(ngModel)]="userForm.postalCode" autofocus required>
                        </div> -->
                        <div class="bl-form-field bl-form-field__buttons mt-5">
                            <button class="bl-btn bl-btn-primary bl-btn__full-width" type="submit">Zarejestruj</button>
                            <!-- <button class="bl-btn bl-btn-primary bl-btn__full-width" type="button" (click)="loginWithGoogle()">Zaloguj przez Google</button> -->
                        </div>
                        <div class="bl-form-field bl-form-field__buttons">
                            <br><a class="link-secondary" [routerLink]="[ '/login' ]">Masz już konto? Zaloguj się</a>
                        </div>
                    </form>
                </div>
            </div>
        </div>
      </div>
    </section>
  </div>