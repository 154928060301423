import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClientRoutingModule } from './client-routing.module';
import { UserComponent } from './user/user.component';
import { ClientComponent } from './client.component';
import { FormsModule } from '@angular/forms';
import { StreamComponent } from './stream/stream.component';
import { StreamsListComponent } from './stream/streams-list.component';
import { StreamChatComponent } from './chat/stream-chat/stream-chat.component';
import { ChatsListComponent } from './chat/chats-list/chats-list.component';
import { ChatComponent } from './chat/chat/chat.component';
import { ChatsComponent } from './chat/chats/chats.component';
import { HttpClientModule } from "@angular/common/http";
import { SharedModule } from '../shared/shared.module';
import { NavbarComponent } from '../shared/navbar/navbar.component';
import { QuizModule } from '../quiz/quiz.module';
import { SideQuizComponent } from './side-quiz/side-quiz.component';
import { SpeakerInfoComponent } from './speaker-info/speaker-info.component';
import { SpeakersListComponent } from './speaker-info/speakers-list/speakers-list.component';
import { ParseTextPipe } from '../pipes/parse-text.pipe';
import { RoomsComponent } from './rooms/rooms.component';



@NgModule({
  declarations: [
    ClientComponent,
    UserComponent,
    StreamComponent,
    StreamsListComponent,
    StreamChatComponent,
    ChatsListComponent,
    ChatComponent,
    ChatsComponent,
    SideQuizComponent,
    SpeakerInfoComponent,
    SpeakersListComponent,
    ParseTextPipe,
    RoomsComponent
    // NavbarComponent
  ],
  imports: [
    CommonModule,
    ClientRoutingModule,
    FormsModule,
    HttpClientModule,
    SharedModule,
    QuizModule,
  ],
  exports:
  [
    NavbarComponent
  ]
})
export class ClientModule { }
