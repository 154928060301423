import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'parseText'
})
export class ParseTextPipe implements PipeTransform {

  transform(value: string): unknown {
    
    const regexp = new RegExp('~', 'g');
    const str = value.replace(regexp, '\n');
    
    return str;
  }

}
