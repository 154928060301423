import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth.service';
import { Router } from '@angular/router';
import { ErrorsTranslateService } from 'src/app/errors-translate.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  public userForm = {
    email: '',
    password: ''
  };

  public errorMsg = '';

  constructor(
    private auth: AuthService,
    private router: Router,
    private errorService: ErrorsTranslateService) {
      this.auth.getUser().then((user) => {
        console.log(user);
        if (user) {
          this.router.navigate(['/u', 'stream', 'example'])
        }
      })
    }

  ngOnInit(): void {
  }

  loginWithEmail() {
    this.auth.emailSignIn(this.userForm.email, this.userForm.password).then(() => {
      this.router.navigate(['u', 'stream', 'example']);
    }).catch((err) => {
      this.errorMsg = this.errorService.getAuthErrorMsg(err.code);
      console.error(err);
    })
  }

  loginWithGoogle() {
    this.auth.googleSignIn().then(() => {
      this.router.navigate(['u', 'stream', 'example']);
    })
  }

  loginWithFacebook() {

  }

  goToRestorePassword(e) {

  }

}
