import { Component, OnInit, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { Chart } from "chart.js";
import { QuizService } from 'src/app/quiz/quiz.service';

@Component({
  selector: 'app-chart',
  templateUrl: './chart.component.html',
  styleUrls: ['./chart.component.scss']
})
export class ChartComponent implements OnInit, AfterViewInit {

  @ViewChild('canvas', {static: false}) canvasRef: ElementRef<HTMLCanvasElement>;

  context: CanvasRenderingContext2D;
  chart: Chart

  constructor(
    private qs: QuizService
  ) { 
    
  }

  ngOnInit(): void {
    // this.qs.currentQuizData.subscribe
  }

  ngAfterViewInit(): void {
    this.context = this.canvasRef.nativeElement.getContext('2d');
    this.chart = new Chart(this.context, {
      type: 'horizontalBar',
      options: {
        legend: {
          display: false
        }
      },
      // data: this.qs.currentQuizData.getValue()
      data: {
        labels: ['A', 'B', 'C', 'D'],
        datasets: [
          {
            backgroundColor: '#023964',
            data: Object.values(this.qs.currentQuizData.getValue()),
            showLine: false,
            label: '',
            minBarLength: 30
          }
        ]
      }
    })

    this.qs.currentQuizData.subscribe(data => {
      let sorted: any = Object.entries(data).sort((a, b) => {
        if (a[0] < b[0]) return -1;
        if (a[0] > b[0]) return 1;
        return 0
      })
      sorted = sorted.map((x: Array<any>) => {
        return x[1];
      })
      console.log(sorted);

      this.chart.data.datasets[0].data = sorted;

      // this.chart.data.datasets.forEach((dataset) => {
      //   dataset.data.pop();
      // });
      // this.chart.update();
      // this.chart.data.datasets.forEach((dataset, index) => {
      //   dataset.data.push(dataArr[index]);
      // })
      this.chart.update();
    })
  }

}
