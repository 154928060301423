import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class StreamService {

  public currentStreamId$ = new BehaviorSubject('');

  constructor(
    private afs: AngularFirestore
  ) { }

  getStreamDataStatic(streamId) {
    return this.afs
    .collection('streams')
    .doc(streamId)
    .collection('staticData')
    .doc('data')
    .valueChanges()
  }

  getStreamDataDynamic(streamId) {
    return this.afs
      .collection('streams')
      .doc(streamId)
      .collection('dynamicData')
      .doc('data')
      .valueChanges()
  } 
}
