<div class="container wrapper-100 bg-pattern">
    <div class="row wrapper-100">
        <ng-container *ngIf="streamDataStatic && streamDataDynamic">
            <div class="col-12 col-lg-9">
                <h2 class="fw-400 text-left mb-4 mt-0 stream-title">Watch online <span class="fw-700"> {{ streamDataDynamic.data.title }}</span></h2>
                <div class="video-wrapper">
                    <!-- <iframe *ngIf="YTVideoUrl" [src]="YTVideoUrl" frameborder="0"
                        allow="autoplay; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen autoplay=true></iframe> -->
                        <iframe [src]="vimeoUrl" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
                </div>
                <div class="stream-description">
                    <p>{{ streamDataStatic.description | parseText }}</p>
                </div>
                <!-- <button class="bl-btn bl-btn-primary bl-btn__full-width mt-4"
                    *ngIf="streamDataDynamic.speakerZoomActive"
                    (click)="goToMeeting(streamDataStatic.joinZoomLink)"
                    >Porozmawiaj z prelegentem</button> -->
                <!-- <div class="questionaire-wrapper">
                    <app-questionarie></app-questionarie>
                </div> -->
            </div>
            <div class="col-12 col-lg-3 stream-chat-wrapper">
                <h2 class="fw-400 text-left mb-4 mt-0 chat-title">Live chat</h2>
                <app-stream-chat [chatId]=chatId$></app-stream-chat>
            </div>
        </ng-container>
        
    </div>
</div>
