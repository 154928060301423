<div class="container">
    <div class="row align-items-center wrapper-100">
        <div class="col-12 col-md-8 offset-md-2">
            <div class="form-wrapper">
                <h1 class="mb-3" *ngIf="auth.user$ | async as user">My account</h1>
                <form #userForm="ngForm" (ngSubmit)="updateUserData()">
                    <div class="bl-form-field">
                        <label for="name">First name</label>
                        <input type="text" class="bl-form-control" name="name" [(ngModel)]="user.name">
                    </div>
                    <div class="bl-form-field">
                        <label for="lastname">Last name</label>
                        <input type="text" class="bl-form-control" name="lastname" [(ngModel)]="user.lastname">
                    </div>
                    <div class="bl-form-field">
                        <label for="address">Address</label>
                        <input type="text" class="bl-form-control" name="address" [(ngModel)]="user.address">
                    </div>
                    <div class="bl-form-field">
                        <label for="postalCode">Postal code</label>
                        <input type="text" class="bl-form-control" name="postalCode" [(ngModel)]="user.postalCode">
                    </div>
                    <div class="bl-form-field">
                        <label for="phone">Phone</label>
                        <input type="text" class="bl-form-control" name="phone" [(ngModel)]="user.phone">
                    </div>
                    <div class="row">
                        <button class="col-12 offset-md-2 col-md-8 offset-lg-4 col-lg-4 bl-btn bl-btn-outline" type="submit">Save</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <p *ngIf="userUpdated">Saved successfully</p>
        </div>
    </div>
</div>