<div class="container">
    <div class="row">
        <ng-container *ngIf="!quizFinished">
            <div class="col-12 min-full-height quiz-wrapper">
                <span *ngIf="type == 'quiz'" class="question-progress">{{currentQuestionId + 1}}/{{totalQuestions}}</span>
                <div class="question-wrapper">
                    <div class="question-content">
                        <p>{{currentQuestion.content}}</p>
                    </div>
                    <div class="question-answers">
                        <div class="row">
                            <div class="col-12" [ngClass]="{'col-lg-6': !dynamicQuiz}">
                                <div class="question-answer"
                                    (click)="markAnswer($event, 'a')"
                                    [ngClass]="{'marked': answersMarked.a && !inactiveState,
                                        'correct': inactiveState && answersMarked.a && type == 'quiz' && currentQuestion.correct == 'a',
                                        'wrong': inactiveState && answersMarked.a && type == 'quiz' && currentQuestion.correct != 'a'}">
                                    <div class="answer-inner">
                                        <div class="answer-letter">A</div>
                                        <div class="answer-content">{{currentQuestion.a}}</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12" [ngClass]="{'col-lg-6': !dynamicQuiz}">
                                <div class="question-answer"
                                    (click)="markAnswer($event, 'b')"
                                    [ngClass]="{'marked': answersMarked.b && !inactiveState,
                                        'correct': inactiveState && answersMarked.b && type == 'quiz' && currentQuestion.correct == 'b',
                                        'wrong': inactiveState && answersMarked.b && type == 'quiz' && currentQuestion.correct != 'b'}">
                                    <div class="answer-inner">
                                        <div class="answer-letter">B</div>
                                        <div class="answer-content">{{currentQuestion.b}}</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12" [ngClass]="{'col-lg-6': !dynamicQuiz}">
                                <div class="question-answer"
                                    (click)="markAnswer($event, 'c')"
                                    [ngClass]="{'marked': answersMarked.c && !inactiveState,
                                        'correct': inactiveState && answersMarked.c && type == 'quiz' && currentQuestion.correct == 'c',
                                        'wrong': inactiveState && answersMarked.c && type == 'quiz' && currentQuestion.correct != 'c'}">
                                    <div class="answer-inner">
                                        <div class="answer-letter">C</div>
                                        <div class="answer-content">{{currentQuestion.c}}</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12" [ngClass]="{'col-lg-6': !dynamicQuiz}">
                                <div class="question-answer"
                                    (click)="markAnswer($event, 'd')"
                                    [ngClass]="{'marked': answersMarked.d && !inactiveState,
                                        'correct': inactiveState && answersMarked.d && type == 'quiz' && currentQuestion.correct == 'd',
                                        'wrong': inactiveState && answersMarked.d && type == 'quiz' && currentQuestion.correct != 'd'}">
                                    <div class="answer-inner">
                                        <div class="answer-letter">D</div>
                                        <div class="answer-content">{{currentQuestion.d}}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <button class="bl-btn bl-btn-primary" (click)="checkAnswer()">Dalej</button>
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="quizFinished">
            <div class="col-12 min-full-height quiz-result-wrapper">
                <ng-container *ngIf="type == 'quiz'">
                    <h2>Dziękujemy za rozwiązanie quizu!</h2>
                    <h3>Twój wynik to {{ correctAnswers }}/{{ totalQuestions }} w czasie {{ timeDiff }} s</h3>
                </ng-container>
                <ng-container *ngIf="type == 'ankieta'">
                    <app-chart></app-chart>
                </ng-container>
                
            </div>
        </ng-container>

    </div>
</div>