import { Component, OnInit } from '@angular/core';
import { Quiz } from '../quiz.model';
import { QuizService } from '../quiz.service';

@Component({
  selector: 'app-quiz-list',
  templateUrl: './quiz-list.component.html',
  styleUrls: ['./quiz-list.component.scss']
})
export class QuizListComponent implements OnInit {

  quizes;

  constructor(
    private qs: QuizService
  ) { }

  ngOnInit(): void {
    this.quizes = this.qs.getQuizList()
  }

}
