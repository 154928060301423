import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { ClientComponent } from './client.component';
import { UserComponent } from './user/user.component';
import { StreamsListComponent } from './stream/streams-list.component';
import { StreamComponent } from './stream/stream.component';
import { AuthGuard } from '../auth/auth.guard';
import { ChatsListComponent } from './chat/chats-list/chats-list.component';
import { ChatsComponent } from './chat/chats/chats.component';
import { ChatComponent } from './chat/chat/chat.component';
import { QuizListComponent } from '../quiz/quiz-list/quiz-list.component';
import { QuizComponent } from '../quiz/quiz/quiz.component';
import { SpeakersListComponent } from './speaker-info/speakers-list/speakers-list.component';
import { SpeakerInfoComponent } from './speaker-info/speaker-info.component';
import { RoomsComponent } from './rooms/rooms.component';


const routes: Routes = [
  {
    path: '',
    component: ClientComponent,
    canActivateChild: [AuthGuard],
    children: [
      {
        path: 'user',
        component: UserComponent
      },
      {
        path: 'streams-list',
        component: StreamsListComponent
      },
      {
        path: 'stream/:id',
        component: StreamComponent
      },
      {
        path: 'chats',
        component: ChatsComponent,
        children: [
          {
            path: ':id',
            component: ChatComponent,
            outlet: 'chat'
          }
        ]
      },
      {
        path: 'quiz',
        component: QuizListComponent,
      },
      {
        path: 'quiz/:quizId',
        component: QuizComponent
      },
      {
        path: 'speakers',
        component: SpeakersListComponent
      },
      {
        path: 'speaker/:speakerId',
        component: SpeakerInfoComponent
      },
      {
        path: 'rooms',
        component: RoomsComponent
      }
    ]
  }
]


@NgModule({
  declarations: [],
  imports: [
    RouterModule.forChild(routes),
    CommonModule
  ],
  exports: [
    RouterModule
  ]
})
export class ClientRoutingModule { }
