import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { map, tap, take, first, filter } from 'rxjs/operators';
import { Quiz } from './quiz.model';
import { AuthService } from '../auth/auth.service';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class QuizService {

  type = new BehaviorSubject('quiz');
  currentQuizData = new BehaviorSubject({
    a: 0,
    b: 0,
    c: 0,
    d: 0
  });

  constructor(
    private afs: AngularFirestore,
    private auth: AuthService
  ) { }

  getQuizList() {
    return this.afs.collection('features').doc('quiz').collection<any>('quizes', (ref) => {
      // return ref.where('visible', '==', true);
      return ref;
    }).snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          return {
            id: a.payload.doc.id,
            title: a.payload.doc.data().title
          }
        })
      })
    )
  }

  getQuiz(id): Promise<Quiz> {
    return this.afs.collection('features').doc('quiz').collection('quizes').doc(id)
      .snapshotChanges()
      .pipe(
        map((actions: any) => {
          const quiz: Quiz = {
            id: actions.payload.id,
            ... actions.payload.data()
          }
          return quiz;
        }),
        take(1)
      ).toPromise()
  }

  getQuizByStreamId(streamId) {
    return this.afs.collection('features').doc('quiz').collection('quizes', ref => {
      return ref.where('streamId', '==', streamId);
    }).snapshotChanges().pipe(
      map((actions: any) => {
        return actions.map(a => {
          return {
            id: a.payload.doc.id,
            ...a.payload.doc.data()
          }
        })
      })
    )
  }

  saveQuizResult(answers, score, quizId, time) {
    const uid = this.auth.user.uid;
    if (uid) {
      const data = {
        uid,
        answers,
        score,
        quizId,
        time
      }
      this.afs.collection('features').doc('quiz').collection('answers').add(data);
    }
  }

  saveAnkietaResults(qid, answer) {
    this.afs.collection('questionaries').doc('results').get().pipe(
      first()
    ).subscribe(data => {
      const results = data.data();
      results[qid][answer] = parseInt(results[qid][answer]) + 1;
      this.afs.collection('questionaries').doc('results').update(results);
    })
  }

  getAnkietaResults(qid) {
    return this.afs.collection('questionaries').doc('results').valueChanges().pipe(
      tap(actions => {
        return actions[qid];
      })
    )
  }
}
