export interface Speaker {
    name: string,
    company: string,
    position?: string,
    zoomLink?: string,
    calendlyLink?: string,
    image?: string,
    email?: string,
    description?: string,
    id?: string
}

export const speakers: Speaker[] = [
    {
        id: 'asdf',
        name: 'Mateusz Kurlit',
        company: 'Berrylife',
        position: 'CEO',
        zoomLink: '',
        calendlyLink: '',
        image: '../../../assets/images/speakers/speaker-female-2.jpg',
        email: 'mateusz@berrylife.pl',
        description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Laudantium facilis, velit eius voluptatum quam, repudiandae expedita voluptate ducimus fugit similique totam impedit, quasi quae id dignissimos maiores error atque magni veniam? Soluta sapiente esse voluptatibus! Dicta nihil sit voluptatum sunt. Libero nostrum nesciunt impedit atque quibusdam ullam fugiat odio eveniet?'
    },
    {
        id: 'fggfg',
        name: 'Monika Żmuda',
        company: 'Proidea',
        position: 'Project Manager',
        zoomLink: '',
        calendlyLink: '',
        image: '../../../assets/images/speakers/speaker-male-1.jpg',
        email: 'monika.zmuda@proidea.org.pl',
        description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Laudantium facilis, velit eius voluptatum quam, repudiandae expedita voluptate ducimus fugit similique totam impedit, quasi quae id dignissimos maiores error atque magni veniam? Soluta sapiente esse voluptatibus! Dicta nihil sit voluptatum sunt. Libero nostrum nesciunt impedit atque quibusdam ullam fugiat odio eveniet?'
    },
    {
        id: 'bbb',
        name: 'Dawid Armatys',
        company: 'Berrylife',
        position: 'Programmer',
        zoomLink: '',
        calendlyLink: 'https://calendly.com/dawid-armatys/dawid-armatys-meeting',
        image: '../../../assets/images/speakers/speaker-male-3.jpg',
        email: 'dawid.armatys@berrylife.pl',
        description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Laudantium facilis, velit eius voluptatum quam, repudiandae expedita voluptate ducimus fugit similique totam impedit, quasi quae id dignissimos maiores error atque magni veniam? Soluta sapiente esse voluptatibus! Dicta nihil sit voluptatum sunt. Libero nostrum nesciunt impedit atque quibusdam ullam fugiat odio eveniet?'
    },
    // {
    //     id: 'asd',
    //     name: 'Ryszard Kaczmarek',
    //     company: 'PKP Cargo',
    //     position: 'Product Manager',
    //     zoomLink: '',
    //     image: '../../../assets/images/speakers/speaker-male-4.jpg',
    //     email: 'ryszard.kaczmarek@pkp.pl',
    //     description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Laudantium facilis, velit eius voluptatum quam, repudiandae expedita voluptate ducimus fugit similique totam impedit, quasi quae id dignissimos maiores error atque magni veniam? Soluta sapiente esse voluptatibus! Dicta nihil sit voluptatum sunt. Libero nostrum nesciunt impedit atque quibusdam ullam fugiat odio eveniet?'
    // },
    // {
    //     id: 'nh',
    //     name: 'Adrianna Kamińska',
    //     company: 'ZIKIT',
    //     position: 'Rzecznik prasowy',
    //     zoomLink: '',
    //     image: '../../../assets/images/speakers/speaker-female-5.jpg',
    //     email: 'ada.kaminska@zikit.pl',
    //     description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Laudantium facilis, velit eius voluptatum quam, repudiandae expedita voluptate ducimus fugit similique totam impedit, quasi quae id dignissimos maiores error atque magni veniam? Soluta sapiente esse voluptatibus! Dicta nihil sit voluptatum sunt. Libero nostrum nesciunt impedit atque quibusdam ullam fugiat odio eveniet?'
    // },
    // {
    //     id: 'asdht',
    //     name: 'Maja Zielińska',
    //     company: 'Global Investment',
    //     position: 'Doradca klienta biznesowego',
    //     zoomLink: '',
    //     image: '../../../assets/images/speakers/speaker-female-6.jpg',
    //     email: 'goralska@gi.zn',
    //     description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Laudantium facilis, velit eius voluptatum quam, repudiandae expedita voluptate ducimus fugit similique totam impedit, quasi quae id dignissimos maiores error atque magni veniam? Soluta sapiente esse voluptatibus! Dicta nihil sit voluptatum sunt. Libero nostrum nesciunt impedit atque quibusdam ullam fugiat odio eveniet?'
    // }
]