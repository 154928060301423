import { Component, OnInit, AfterContentChecked, ViewChild, ElementRef, AfterViewChecked, Input } from '@angular/core';
import { ChatService } from '../chat.service';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/auth/auth.service';
import { Observable, BehaviorSubject, of } from 'rxjs';
import { tap, take, takeUntil, takeWhile, concatMap } from 'rxjs/operators';

@Component({
  selector: 'app-stream-chat',
  templateUrl: './stream-chat.component.html',
  styleUrls: ['./stream-chat.component.scss']
})
export class StreamChatComponent implements OnInit, AfterViewChecked {

  chat$: Observable<any>;
  newMsg: string;
  chatId: string;
  // streamId;

  @ViewChild('chatWindow', {static: false}) chatWindowRef: ElementRef<HTMLElement>;

  @Input('chatId') chatId$: BehaviorSubject<string>;

  constructor(
    public cs: ChatService,
    private route: ActivatedRoute,
    public auth: AuthService
  ) { }

  ngOnInit(): void {
    let source;
    this.chatId$.pipe(
      takeWhile((val) => {
        return val === '';
      }, true)
    ).subscribe(chatId => {
      if (chatId) {
        this.chatId = chatId;
        source = this.cs.getStreamChat(chatId);
        this.chat$ = this.cs.joinStreamChatUsers(source)
      }
    })
  }

  ngAfterViewChecked(): void {
    if (this.chatWindowRef) {
      const chatWindow = this.chatWindowRef.nativeElement;
      chatWindow.scrollTo(0, chatWindow.scrollHeight);
    }
  }

  submit() {
    if (!this.newMsg.length) return;
    this.cs.sendMessage(this.chatId, this.newMsg);
    this.newMsg = '';
  }

  trackByCreated(i, msg) {
    return msg.createdAt;
  }

  acceptMessage(i, msg) {
    this.cs.acceptMessage(this.chatId, i)
  }

  deleteMessage(i, msg) {
    this.cs.deleteMessage(this.chatId, i);
  }

}
