<div class="container wrapper-100">
    <div class="row page-heading">
        <div class="logo logo-header">
            <img src="../../../assets/images/logo-header.svg" alt="Logo Berrylife">
        </div>
        <div class="main-navbar">
            <nav class="bl-main-nav" [ngClass]="{'opened': navOpened}">
                <ul>
                    <!-- <li><a class="link-secondary" [routerLink]="['/u', 'streams-list']"
                            routerLinkActive="active">Transmisje</a></li> -->
                    <!-- <li><a class="link-secondary" [routerLink]="['/u', 'stream', '0yYrzSyia8GDpYUYfIOX']"
                        routerLinkActive="active">Transmisja 1</a></li> -->
                    <li><a class="link-secondary" [routerLink]="['/u', 'stream', 'example']"
                        routerLinkActive="active">Stream</a></li>
                    <!-- <li><a class="link-secondary" [routerLink]="['/u', 'agenda']"
                            routerLinkActive="active">Agenda</a></li> -->
                    <li><a class="link-secondary" [routerLink]="['/u', 'rooms']"
                        routerLinkActive="active">Rooms</a></li>
                    <li><a class="link-secondary" [routerLink]="['/u', 'chats', {id: 'global'}]"
                            routerLinkActive="active">Chat</a></li>
                    <li><a class="link-secondary" [routerLink]="['/u', 'speakers']"
                        routerLinkActive="active">Participants</a></li>
                    <button class="bl-btn bl-btn-primary" (click)="logout()">Wyloguj</button>
                </ul>
            </nav>
            <div class="nav-profile" *ngIf="user">
                <img src="{{user.photoURL}}" alt="User" [routerLink]="['/u', 'user']">
            </div>
            <div class="mobile-menu-toggler">
                <input type="checkbox" [(ngModel)]="navOpened">
                <span class="first-bar"></span>
                <span class="second-bar"></span>
                <span class="third-bar"></span>
            </div>
        </div>
    </div>
</div>