import { Component, OnInit, ViewChild, ElementRef, AfterViewChecked } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { map, switchMap, tap, mergeMap } from 'rxjs/operators';
import { ChatService } from '../chat.service';
import { AuthService } from 'src/app/auth/auth.service';
import { Chat } from '../models/chat.model';

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss']
})
export class ChatComponent implements OnInit, AfterViewChecked {

  chatId;
  chat$;
  newMsg: string;


  @ViewChild('chatWindow', {static: false}) chatWindowRef: ElementRef<HTMLElement>;

  constructor(
    private route: ActivatedRoute,
    private cs: ChatService,
    public auth: AuthService
  ) { }

  ngOnInit(): void {
    let source;
    this.route.params.subscribe((params) => {
      if (params.id) {
        this.chatId = params.id;
        source = this.cs.getChat(params.id);
        this.chat$ = this.cs.joinStreamChatUsers(source)
      }
    })
  }

  ngAfterViewChecked(): void {
    if (this.chatWindowRef) {
      const chatWindow = this.chatWindowRef.nativeElement;
      chatWindow.scrollTo(0, chatWindow.scrollHeight);
    }
  }

  submit() {
    if (!this.newMsg.length) return;
    this.cs.sendMessage(this.chatId, this.newMsg);
    this.newMsg = '';
  }

  trackByCreated(i, msg) {
    return msg.createdAt;
  }

  isMyMessage(createdBy) {
    if (this.auth.user) {
      const {uid} = this.auth.user
      if (uid == createdBy) {
        return true;
      } else {
        return false;
      }
    }
  }

  joinZoom(url) {
    window.open(url, '_blank');
  }

  deleteMessage(i, msg) {
    this.cs.deleteMessage(this.chatId, i);
  }

  copyChat(chat) {
    delete chat.id
    console.log(chat);
    this.cs.copyChat(chat)
  }
}
