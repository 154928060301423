<div class="container bl-chats">
    <div class="row min-full-height">
        <!-- <div class="col-12 col-lg-4 col-xl-3 chat-list-wrapper">
            <app-chats-list></app-chats-list>
        </div> -->
        <h2>Chat</h2>
        <div class="col-12 chat-wrapper">
            <app-chat></app-chat>
        </div>
    </div>
</div>