import { Component, OnInit, ViewChild, ViewContainerRef, ComponentRef, ComponentFactoryResolver, ComponentFactory, ChangeDetectorRef } from '@angular/core';
import { SideQuizService } from './side-quiz.service';
import { QuizComponent } from 'src/app/quiz/quiz/quiz.component';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-side-quiz',
  templateUrl: './side-quiz.component.html',
  styleUrls: ['./side-quiz.component.scss']
})
export class SideQuizComponent implements OnInit {
  @ViewChild('panelContainer', {read: ViewContainerRef}) container: ViewContainerRef;
  componentRef: ComponentRef<QuizComponent>

  opened = false
  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    public sqSrv: SideQuizService,
    private resolver: ComponentFactoryResolver,
    private cd: ChangeDetectorRef
    ) { }

  ngOnInit(): void {
    this.sqSrv.quizOpened.subscribe(opened => {
      this.opened = opened;
    })
  }

  createComponent = (dynamic) => {
    if (this.container) {
      this.container.clear();
    }
    const factory: ComponentFactory<QuizComponent> = this.resolver.resolveComponentFactory(QuizComponent);
    this.componentRef = this.container.createComponent(factory);
    this.componentRef.instance.dynamicQuiz = dynamic
  }

  ngAfterViewInit(): void {
    this.sqSrv.quizActive.pipe(
      takeUntil(this.destroy$)
    ).subscribe((type) => {
      console.log(type);
      if (type) {
        this.createComponent(type);
      } else {
        if (this.componentRef) {
          console.log('destroy');
          this.componentRef.destroy();
        }
      }
      this.cd.detectChanges();
    })
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    if (this.componentRef) {
      this.componentRef.destroy();
    }
  }
}
