import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './auth/login/login.component';
import { RegisterComponent } from './auth/register/register.component';
import { SelectivePreloadingStrategyService } from './selective-preloading-strategy.service';
import { AuthGuard } from './auth/auth.guard';
import { LoginGuard } from './auth/login/login.guard';
import { ModeratorGuard } from './auth/guards/moderator.guard';
import { AdminGuard } from './auth/guards/admin.guard';


const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'login'
  },
  {
    path: 'login',
    component: LoginComponent,
    // canActivate: [LoginGuard]
  },
  {
    path: 'register',
    component: RegisterComponent,
    // canActivate: [LoginGuard]
  },
  {
    path: 'u',
    loadChildren: './client/client.module#ClientModule',
    canActivate: [AuthGuard]
  },
  {
    path: 'moderator',
    loadChildren: './moderator/moderator.module#ModeratorModule',
    canActivate: [ModeratorGuard],
    canActivateChild: [ModeratorGuard]
  },
  {
    path: 'admin',
    loadChildren: './admin/admin.module#AdminModule',
    canActivate: [AdminGuard],
    canActivateChild: [AdminGuard]
  },
  {
    path: '**',
    redirectTo: '/u/stream/example'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    preloadingStrategy: SelectivePreloadingStrategyService
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
