import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { StreamService } from './stream.service';
import { DomSanitizer } from '@angular/platform-browser';
import { Observable, of, BehaviorSubject } from 'rxjs';
import { SideQuizService } from '../side-quiz/side-quiz.service';

@Component({
  selector: 'app-stream',
  templateUrl: './stream.component.html',
  styleUrls: ['./stream.component.scss']
})
export class StreamComponent implements OnInit {

  streamDataStatic;
  streamDataDynamic;
  dangerousYTVideoUrl = 'https://www.youtube.com/embed/';
  dangerousVimeoUrl = 'https://player.vimeo.com/video/';
  // https://player.vimeo.com/video/425851998?autoplay=1&color=023964&title=0&byline=0&portrait=0
  YTVideoUrl;
  vimeoUrl;
  chatId$ = new BehaviorSubject('');

  constructor(
    private activatedRoute: ActivatedRoute,
    private streamSrv: StreamService,
    private sanitizer: DomSanitizer,
    private sqSrv: SideQuizService
    ) {
      const streamId = this.activatedRoute.snapshot.paramMap.get('id');
      if (streamId) {
        this.streamSrv.currentStreamId$.next(streamId);
        this.sqSrv.quizActive.next('dynamic');
      }
      this.streamSrv.getStreamDataStatic(streamId).subscribe((staticData: any) => {
        this.streamDataStatic = staticData;
        this.chatId$.next(staticData.chatId);
        // this.dangerousYTVideoUrl += staticData.YTVideoID;
        this.dangerousVimeoUrl += staticData.vimeoId;
        this.dangerousVimeoUrl += '?autoplay=1&color=023964&title=0&byline=0&portrait=0';
        // this.YTVideoUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.dangerousYTVideoUrl);
        this.vimeoUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.dangerousVimeoUrl);
      })
      this.streamSrv.getStreamDataDynamic(streamId).subscribe((dynamicData: any) => {
        this.streamDataDynamic = dynamicData;
      })
    }

  ngOnInit(): void {
    
  }

  goToMeeting(url) {
    window.open(url, '_blank')
  }

  ngOnDestroy(): void {
    this.sqSrv.quizOpened.next(false)
    this.streamSrv.currentStreamId$.next('');
  }

}
