<div class="client-wrapper">
    <section class="top-bar">
      <div class="container">
        <div class="row page-heading login-heading">
            <div class="logo logo-header logo-header__login">
                <img src="../../../assets/images/logo-header.svg" alt="Logo Berrylife">
            </div>
            <div class="login-heading__info">
                <span>GRAND FINAL</span>
            </div>
        </div>
      </div>
    </section>
    <section class="bl-main-wrapper">
      <div class="container">
        <div class="row login-wrapper wrapper-100">
            <div class="col-12 col-md-8 offset-md-2 col-lg-4 offset-lg-4">
                <div class="form-wrapper">
                    <h2>SIGN IN</h2>
                    <form #loginForm="ngForm" (ngSubmit)="loginWithEmail()">
                        <div class="bl-form-field">
                            <label for="email" class="input-label">Email</label>
                            <input type="email" class="bl-form-control" id="email" name="email" [(ngModel)]="userForm.email" autofocus required>
                        </div>
                        <div class="bl-form-field">
                            <label for="password" class="input-label">Password</label>
                            <input type="password" class="bl-form-control" id="password" name="password" [(ngModel)]="userForm.password" autofocus required>
                        </div>
                        <div class="bl-form-field bl-form-field__buttons">
                            <button class="bl-btn bl-btn-outline" type="submit">Sign in</button>
                        </div>
                        <div class="bl-form-field" *ngIf="errorMsg">
                            <p class="error-message">{{errorMsg}}</p>
                        </div>
                        <!-- <div class="bl-form-field bl-form-field__buttons mt-5">
                            <button class="bl-btn bl-btn-primary bl-btn__full-width" type="button" (click)="loginWithGoogle()">Zaloguj przez Google</button>
                        </div> -->
                    </form>
                </div>
            </div>
        </div>
      </div>
    </section>
</div>