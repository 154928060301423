import { Injectable } from '@angular/core';
import { User } from "./user.model";

import { auth, firestore } from 'firebase/app';
import 'firebase/auth';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { Observable, of } from 'rxjs';
import { switchMap, first } from "rxjs/operators";
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  user$: Observable<User>;
  user: User;

  constructor(
    private afAuth: AngularFireAuth,
    private afs: AngularFirestore,
    private router: Router
  ) {
    this.user$ = afAuth.authState.pipe(
      switchMap(user => {
        if (user) {
          return this.afs.doc<User>(`users/${user.uid}`).valueChanges();
        } else {
          return of(null);
        }
      })
    )

    this.getUser().then(user => {
      this.user = user;
    })
  }

  isAdmin(user = this.user) {
    const allowed = ['admin']
    return this.checkAuthorization(user, allowed);
  }

  isModerator(user = this.user) {
    const allowed = ['admin', 'moderator']
    return this.checkAuthorization(user, allowed);
  }

  getUser() {
    return this.user$.pipe(first()).toPromise();
  }

  async googleSignIn() {
    const provider = new auth.GoogleAuthProvider();
    const credential = await this.afAuth.signInWithPopup(provider);
    console.log(credential);
    return this.updateUserData(credential.user);
  }

  async emailSignIn(email, password, extraData?: any) {
    const provider = new auth.EmailAuthProvider();
    const result = await this.afAuth.signInWithEmailAndPassword(email, password);
    // return this.updateUserData(result.user)
  }

  async registerWithEmail(data) {
    const userData: User = {
      email: data.email,
      uid: '',
      address: data.address,
      name: data.name,
      lastname: data.lastname,
      phone: data.phone ? data.phone : null,
      postalCode: data.postalCode ? data.postalCode : null,
      displayName: `${data.name} ${data.lastname}`,
      roles: {
        client: true
      }
    }
    try {
      const result = await this.afAuth.createUserWithEmailAndPassword(data.email, data.password);
      console.log(result)
      userData.uid = result.user.uid;
      return this.updateUserData(result.user, userData);
    } catch (err) {
      console.error(err.code);
    }
  }
  
  updateUserData({uid, email, displayName, photoURL}, myCustomData?) {
    const userRef: AngularFirestoreDocument<User> = this.afs.doc(`users/${uid}`)

    // myCustomData.uid = uid;
    console.log(myCustomData);

    const data: User = {
      uid,
      email,
      displayName,
      photoURL: `https://eu.ui-avatars.com/api/?background=F05038&color=fff&name=${myCustomData? myCustomData.name : ''}+${myCustomData? myCustomData.lastname : ''}`,
      roles: {
        client: true
      },
      ...myCustomData
    }

    return userRef.set(data, {merge: true})
  }

  private checkAuthorization(user: User, allowedRoles: string[]): boolean {
    // TODO: Zamienić żeby zwracało klienta jeżeli platforma jest bez logowania
    if (!user) return false;
    for (const role of allowedRoles) {
      if (user.roles[role]) {
        return true;
      }
    }
    return false;
  }

  async signOut() {
    await this.afAuth.signOut();
    this.router.navigate(['/login']);
  }

  handleError(err) {
    console.error(err);
  }
}
