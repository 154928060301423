
<ng-container *ngIf="chat$ | async as chat">
    <div class="chat" #chatWindow>
      <div class="msg" *ngFor="let msg of chat.messages; trackBy: trackByCreated; let i = index">
        <div class="user-image">
          <img [src]="msg.user?.photoURL">
        </div>
        <div class="message-wrapper">
          <div class="message-text">
            <p class="message-content">{{ msg.content }}</p>
          </div>
          <div class="message-meta">
            <div class="moderator-buttons" *ngIf="auth.isModerator()">
              <span class="accept" (click)="acceptMessage(i, msg)" *ngIf="!msg.accepted">
                <svg xmlns="http://www.w3.org/2000/svg" width="11.096" height="8.307" viewBox="0 0 11.096 8.307">
                  <path id="accept-mark" d="M1015.379,420.683l-.769-.77a.6.6,0,0,0-.841,0l-5.158,5.159-2.369-2.369a.6.6,0,0,0-.841,0l-.769.77a.592.592,0,0,0,0,.84l3.558,3.559a.6.6,0,0,0,.841,0h0l6.347-6.347A.593.593,0,0,0,1015.379,420.683Z" transform="translate(-1004.457 -419.739)" fill="#35415e"/>
                </svg>
              </span>
              <span class="decline" (click)="deleteMessage(i, msg)">
                <svg xmlns="http://www.w3.org/2000/svg" width="8.307" height="8.306" viewBox="0 0 8.307 8.306">
                  <path id="decline-mark" d="M1031.184,426.339l-2.369-2.369,2.369-2.369a.594.594,0,0,0,0-.84l-.77-.77a.6.6,0,0,0-.841,0l-2.369,2.369-2.368-2.369a.6.6,0,0,0-.841,0l-.77.77a.594.594,0,0,0,0,.84l2.369,2.369-2.369,2.369a.594.594,0,0,0,0,.84l.77.77a.6.6,0,0,0,.841,0l2.368-2.369,2.369,2.369a.6.6,0,0,0,.841,0l.77-.77A.594.594,0,0,0,1031.184,426.339Z" transform="translate(-1023.051 -419.817)" fill="#35415e"/>
                </svg>                
              </span>
            </div>
            <span class="user-name">{{ msg.user?.displayName }},&nbsp;</span>
            <span class="time" *ngIf="msg.createdAt > 0">{{ msg.createdAt | date:'shortTime' }}</span>
          </div>
        </div>
      </div>
    </div>

    <div class="form">
      <div class="bl-form-field">
        <input class="bl-form-control" [(ngModel)]="newMsg" placeholder="Type something here..." (keydown.enter)="submit()">
        <i class="chat-send-btn" (click)="submit()"><svg xmlns="http://www.w3.org/2000/svg" ><defs><style>.send-message-path{fill:none;stroke:#ccc;stroke-miterlimit:10;stroke-width:0.5px;}</style></defs><path class="send-message-path" d="M2481.411,638.179l-15.769-7.977a.4.4,0,0,0-.554.5l2.894,7.43a.392.392,0,0,1,0,.29l-2.907,7.465a.4.4,0,0,0,.545.507l15.782-7.5A.4.4,0,0,0,2481.411,638.179Z" transform="translate(-2464.789 -629.898)"/></svg></i>
      </div>

      <!-- <button (click)="submit()">Send</button> -->
      <!-- <pre>{{newMsg}}</pre> -->
    </div>

</ng-container>
