import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-streams-list',
  templateUrl: './streams-list.component.html',
  styleUrls: ['./streams-list.component.scss']
})
export class StreamsListComponent implements OnInit {

  streams: Observable<any>;

  constructor(
    private afs: AngularFirestore
  ) { }

  ngOnInit(): void {
    this.streams = this.afs.collection('streams').snapshotChanges().pipe(
      map(actions => actions.map(a => {
        const data = a.payload.doc.data() as any;
        const id = a.payload.doc.id;
        console.log(id, data);
        return {id, ...data};
      }))
    )
  }

}
