import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {

  public userForm = {
    email: '',
    password: '',
    phone: '',
    postalCode: '',
    address: '',
    name: '',
    lastname: ''
  };

  constructor(
    private auth: AuthService,
    private router: Router
  ) { }

  ngOnInit(): void {
  }

  register() {
    const extraData = {
      phone: this.userForm.phone,
      postalCode: this.userForm.postalCode,
      address: this.userForm.address
    };
    this.auth.registerWithEmail(this.userForm)
      .catch(err => console.log)
      .then(() => {
        this.router.navigate(['/u', 'user']);
      })
  }

}
