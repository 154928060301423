import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AuthService } from 'src/app/auth/auth.service';
import { Router } from '@angular/router';
import { map, switchMap, filter, tap, first } from 'rxjs/operators';
import { Observable, combineLatest, of, from } from 'rxjs';
import { ChatMessage } from "./models/message.model";
import { Chat } from './models/chat.model';
import { firestore } from 'firebase/app';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment'
import { User } from 'src/app/auth/user.model';

@Injectable({
  providedIn: 'root'
})
export class ChatService {

  currentStreamChatId: string;

  constructor(
    private afs: AngularFirestore,
    private auth: AuthService,
    private router: Router,
    private http: HttpClient
  ) { }
  
  getStreamChat(chatId) {
    return this.afs.collection<any>(`chats`)
      .doc(chatId)
      .snapshotChanges()
      .pipe(
        map((doc: any) => {
          return {id: doc.payload.id, ...doc.payload.data()}
        }),
        tap((chat: Chat) => {
          if (this.auth.isModerator()) return chat;
          chat.messages = chat.messages.filter(val => {
            return val.accepted;
          });
          return chat;
        }),
      );
  }

  getStreamChatForModerator(chatId) {
    return this.afs.collection<any>(`chats`)
      .doc(chatId)
      .snapshotChanges()
      .pipe(
        map((doc: any) => {
          return {id: doc.payload.id, ...doc.payload.data()}
        })
      );
  }

  acceptMessage(chatId, messageIndex) {
    return this.afs.collection<any>('chats')
      .doc(chatId).get().toPromise().then((data) => {
        const messages = data.data().messages;
        messages[messageIndex].accepted = true;
        
        return this.afs.collection<any>('chats')
          .doc(chatId)
          .update({messages});
      })
  }

  deleteMessage(chatId, messageIndex) {
    return this.afs.collection<any>('chats')
      .doc(chatId).get().toPromise().then((data) => {
        const messages = data.data().messages;
        messages.splice(messageIndex, 1);
        // messages[messageIndex].accepted = true;

        return this.afs.collection<any>('chats')
          .doc(chatId)
          .update({messages});
      })
  }
  

  async sendMessage(chatId, content) {
    const {uid} = await this.auth.getUser();

    const data: ChatMessage = {
      uid,
      content,
      createdAt: Date.now(),
      accepted: false
    };

    if (uid) {
      const ref = this.afs.collection(`chats`).doc(chatId);
      return ref.update({
        messages: firestore.FieldValue.arrayUnion(data)
      });
    }
  }

  joinStreamChatUsers(chat$: Observable<any>) {
    let chat;
    const joinKeys = {};

    return chat$.pipe(
      switchMap(c => {
        chat = c;
        const uids = Array.from(new Set(c.messages.map(v => v.uid)));
        const userDocs = uids.map(u => {
          return this.afs.doc(`users/${u}`).valueChanges()
        });

        return userDocs.length ? combineLatest(userDocs) : of([]);
      }),
      map(arr => {
        arr.forEach(v => (joinKeys[(<any>v).uid] = v));
        chat.messages = chat.messages.map(v => {
          return {...v, user: joinKeys[v.uid]};
        });
        return chat;
      })
    );
  }

  getChat(chatId) {
    if (!chatId) return of(null);
    return this.afs.collection<any>(`chats`)
      .doc(chatId)
      .snapshotChanges()
      .pipe(
        map((doc: any) => {
          return {id: doc.payload.id, ...doc.payload.data()}
        })
      );
  }

  async createChat(secondUid?) {
    const {uid} = await this.auth.getUser();

    const assignedUsers = [uid];
    if (secondUid) {
      assignedUsers.push(secondUid);
    }

    const data = {
      owner: uid,
      assignedUsers,
      createdAt: Date.now(),
      accepted: false,
      message: ''
    }

    const docRef = await this.afs.collection('chats').add(data);
  }

  // TODO: Zamienić na cloud function żeby pobierało tylko id, title, last message
  async getChatsList() {
    const {uid, displayName} = await this.auth.getUser();

    return this.afs.collection<any>('chats', (ref) => {
      return ref.where('assignedUsers', 'array-contains-any', ['all', uid]);
    }).snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = {
            id: a.payload.doc.id,
            ...a.payload.doc.data()
          }
          return data;
        }).filter(c => {
          return c.streamChat !== true;
        })
      })
    )
  }

  copyChat(chat) {
    this.afs.collection('chats').add(chat);
  }
}
